import ServiceExec from '@/common/utils/ServiceExec';

/**
 * 견적문의 Service
 */
class EstimateSvc extends ServiceExec {
  saveEstimate(params = {}) {
    return this.post(`/api/estimate`, params).then(response => {
      return response.data;
    });
  }
}
export default new EstimateSvc();
