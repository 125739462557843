import ConstCode from '@/common/constants/ConstCode';
import Util from '@/common/utils/Util';
import router from '@/router';

export const nativeFinish = () => {
  location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=finish`;
};

window.fnBack = async () => {
  if (!Util.isEmpty(window.routeQuery.isRoot) && Number(window.routeQuery.isRoot) > 0) {
    nativeFinish();
    return;
  }
  router.go(-1);
};
