<template>
  <div class="pop_cover on" v-if="isShow || isForceLoading">
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, reactive, toRefs, watch } from 'vue';

export default {
  name: 'spinner-index',
  setup() {
    const { getters } = useStore();
    const state = reactive({
      isLoading: computed(() => getters['layout/getIsLoading']),
      isForceLoading: computed(() => getters['layout/getIsForceLoading']),
      isShow: false,
      timer: ''
    });
    /** watch **/
    watch(
      () => state.isLoading,
      val => {
        if (state.timer) {
          clearTimeout(state.timer);
          state.timer = '';
        }
        if (val) {
          state.isShow = true;
        } else {
          state.timer = setTimeout(() => {
            state.isShow = false;
          }, 100);
        }
      }
    );
    return { ...toRefs(state) };
  }
};
</script>

<style>
.lds-facebook {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #e50e0e;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
</style>
