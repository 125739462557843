<template>
  <div v-observe-visibility="visibilityChanged"></div>
</template>

<script>
import { watch, reactive, toRefs } from 'vue'

export default {
  name: 'component-observe-visibility-index',
  props: {
    page: {
      type: [Number],
      default: 0
    },
    limit: {
      type: [Number],
      default: 10
    },
    totalCnt: {
      type: [Number],
      default: 0
    }
  },
  emits: ['fetch'],
  setup(props, { emit }) {
    const state = reactive({
      callCnt: 0,
      isVisible: false,
      isActive: true
    })
    const visibilityChanged = async isVisible => {
      state.isVisible = isVisible
      await fnFetch()
    }
    const fnFetch = async () => {
      if (state.isVisible && state.isActive) {
        if (state.callCnt > 0 && props.totalCnt <= props.page * props.limit) {
          state.isActive = false
        } else {
          await emit('fetch')
        }
        state.callCnt++
      }
    }
    /** watch **/
    watch(
      () => props.page,
      async () => {
        await fnFetch()
      }
    )
    return { ...props, ...toRefs(state), visibilityChanged }
  }
}
</script>

<style scoped></style>
