import axios from 'axios'
import store from '@/store'
// import Util from '@/common/utils/Util'
import queryString from 'query-string'
import router from '@/router'

let _this = null
class ServiceExec {
  constructor() {
    let ServiceExec = axios.create({
      headers: { csrf: 'ownerAppMap' },
      withCredentials: true
    })
    // setLoading
    ServiceExec.interceptors.request.use(this.handleRequest)
    ServiceExec.interceptors.response.use(this.handleSuccess, this.handleError)
    this.ServiceExec = ServiceExec
    this.store = store
    this.router = router
    this.displayNoneLoadingUrl = ['/apis/searchoutlet']
    _this = this
  }

  async handleRequest(request) {
    if (_this.displayNoneLoadingUrl.indexOf(request.url) < 0) {
      await store.dispatch('layout/updateIsLoading', { isLoading: true })
    }
    return request
  }

  async handleSuccess(response) {
    if (_this.displayNoneLoadingUrl.indexOf(response.config.url) < 0) {
      await store.dispatch('layout/updateIsLoading', { isLoading: false })
    }
    return response
  }

  async handleError(error) {
    switch (error.response.status) {
      case 401: // 권한 없음
        await store.dispatch('layout/updateIsLoading', { isLoading: false })
        await store.dispatch('user/signOut')
        break
      case 403: // expireDate 체크 -> 토큰 재발급
        await store.dispatch('layout/updateIsLoading', { isLoading: false })
        alert('서버와 통신이 원활하지 않습니다.')
        break
      case 404:
        await store.dispatch('layout/updateIsLoading', { isLoading: false })
        alert('서버와 통신이 원활하지 않습니다.')
        break
      default:
        await store.dispatch('layout/updateIsLoading', { isLoading: false })
        alert('서버와 통신이 원활하지 않습니다.')
        break
    }
    return Promise.reject(error)
  }

  get(path, params = {}) {
    return this.ServiceExec.get(path, { params })
  }

  patch(path, payload) {
    return this.ServiceExec.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  post(path, payload) {
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  postResponseText(path, payload) {
    payload = `${queryString.stringify(payload)}`
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'text',
      data: payload
    })
  }

  delete(path, payload) {
    payload = `${path}?${queryString.stringify(payload)}`
    return this.ServiceExec.delete(payload)
  }

  queryPost(path, payload) {
    payload = `${queryString.stringify(payload)}`
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  queryMultiPost(path, payload) {
    payload = `${queryString.stringify(payload)}`
    return this.ServiceExec.request({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  multiPost(path, payload) {
    return this.ServiceExec.request({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
      onUploadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length'
            )
        console.log('onUploadProgress', totalLength)
        if (totalLength !== null) {
          console.log(Math.round((progressEvent.loaded * 100) / totalLength))
        }
      }
    })
  }
}

export default ServiceExec
