// import { merge } from 'lodash'

import PortfolioSvc from '@/common/service/PortfolioSvc';

export const state = {
  list: []
};

export const getters = {
  list: ({ list }) => list
};
export const mutations = {
  list: (state, list = []) => (state.list = list)
};
export const actions = {
  getPortfolioList: async ({ commit }, params) => {
    const { code, entity } = await PortfolioSvc.getPortfolioList(params);
    if (code !== 1) return;
    commit('list', entity.list);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
