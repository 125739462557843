<template>
  <div class="pop_cover">
    <div class="pop_base">
      <div class="inner">
        <div class="pop_cont clearfix">
          <div class="cont_inner">
            <button class="btn_pop_cls" @click="$emit('close')"></button>
            <div class="pop_sec left">
              <div class="slide_area">
                <div class="slide">
                  <swiper
                    tag="ul"
                    class="slide_ul clearfix"
                    :slides-per-view="1"
                    @slideChange="onSlideChange"
                    @swiper="onSwiper"
                  >
                    <swiper-slide v-for="(item, index) in data.imageList" :key="`image_${index}`">
                      <li>
                        <div class="img_wrap" :class="{ pc: imgHeight < imgWidth }">
                          <!--                          <img src="@/assets/static/images/app_img/2019/danjicam/portpolio_danjicam01.png" />-->
                          <img :ref="`image_${index}`" :src="$ConstCode.getImagePath(item.storedFileName)" />
                        </div>
                      </li>
                    </swiper-slide>
                  </swiper>
                  <!--                  <ul class="slide_ul clearfix">
                    <li>
                      <div class="img_wrap">
                        <img src="@/assets/static/images/app_img/2019/danjicam/portpolio_danjicam01.png" />
                      </div>
                    </li>
                  </ul>-->
                  <button class="btn_slide left" @click="swiper.slidePrev()"></button>
                  <button class="btn_slide right" @click="swiper.slideNext()"></button>
                </div>
                <ul class="pager clearfix" v-if="!$Util.isEmpty(data.imageList)">
                  <li
                    v-for="index in data.imageList.length"
                    :key="index"
                    :class="{ on: index === activeIndex + 1 }"
                    @click="swiper.slideTo(index - 1)"
                  ></li>
                </ul>
              </div>
            </div>
            <div class="pop_sec right">
              <div class="title_area">
                <!--                <h3>대기업</h3>-->
                <h1>{{ data.projectName }}</h1>
              </div>
              <div class="tag_area">
                <ul class=" clearfix">
                  <li v-for="(item, index) in data.tags" :key="`tag_${index}`">
                    <p>{{ item }}</p>
                  </li>
                  <!--                  <li><p>Android</p></li>
                  <li><p>카메라</p></li>-->
                </ul>
              </div>
              <div class="txt_area">
                <p v-html="data.projectDescription"></p>
              </div>
              <div class="btn_area">
                <button class="apple" v-if="!$Util.isEmpty(data.iosUrl)" @click="fnLink(data.iosUrl)"></button>
                <button class="google" v-if="!$Util.isEmpty(data.androidUrl)" @click="fnLink(data.androidUrl)"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue';
import PortfolioSvc from '@/common/service/PortfolioSvc';
import { modalAlertNames } from '@/components/common/globalComponent';
import store from '@/store';

export default {
  name: 'modal-portfolio-detail',
  emits: ['close'],
  props: {
    params: {
      type: Object
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeIndex: 0,
      data: {},
      swiper: null,
      imgHeight: 0,
      imgWidth: 0
    });

    // state.imgHeight = proxy.$refs[`image_0`].height;
    // state.imgWidth = proxy.$refs[`image_0`].width;

    const onSlideChange = event => {
      state.activeIndex = event.activeIndex;
      // 홈페이지 이미지와 모바일 이미지를 구분하기 위함
      state.imgHeight = proxy.$refs[`image_${state.activeIndex}`].height;
      state.imgWidth = proxy.$refs[`image_${state.activeIndex}`].width;
    };

    const getPortfolio = async () => {
      const res = await PortfolioSvc.getPortfolio(props.params.portfolioNo);
      if (res.code === 1) {
        state.data = res.entity;
        state.data.tags = state.data.tag.split(',');
      } else {
        const payload = {};
        payload.component = modalAlertNames.BASE;
        payload.layout = {
          contents: res.message
        };
        await store.dispatch('layout/pushModalAlertComponent', payload);
      }
    };

    const onSwiper = swiper => {
      state.swiper = swiper;
      // 홈페이지 이미지와 모바일 이미지를 구분하기 위함
      const height = setInterval(() => {
        state.imgHeight = proxy.$refs[`image_${state.activeIndex}`].height;
        state.imgWidth = proxy.$refs[`image_${state.activeIndex}`].width;
        if (state.imgHeight > 0) {
          clearInterval(height);
        }
      }, 100);
    };

    const fnLink = url => {
      window.open(url);
    };

    getPortfolio();
    return {
      ...toRefs(state),
      onSlideChange,
      onSwiper,
      fnLink
    };
  }
};
</script>

<style scoped></style>
