<template>
  <div>
    <teleport to="body">
      <spinner></spinner>
    </teleport>
    <div class="wrap" id="wrap">
      <div class="body">
        <header-component />
        <router-view v-slot="{ Component }" v-show="Util.isEmpty(modalComponents)">
          <transition name="fade">
            <keep-alive :include="keepAlive">
              <component :is="Component"></component>
            </keep-alive>
          </transition>
        </router-view>
        <footer-component />
      </div>
      <!-- 전체화면 modal component -->
      <!--      <teleport to="#wrap" v-for="(item, index) in modalComponents" :key="index">-->
      <!--        <component :index="index" :is="item.component.name" v-bind="item" @close="fnCloseModal" />-->
      <!--      </teleport>-->
      <!-- alert modal component -->
      <teleport to="#wrap" v-for="(item, index) in modalAlertComponents" :key="index">
        <component :index="index" :is="item.component" v-bind="item" @close="fnCloseModalAlert" />
      </teleport>
    </div>
  </div>
</template>

<script setup>
// import HeaderBar from '@/components/common/layouts/header-bar/index.vue';
// import FooterBar from '@/components/common/layouts/footer-bar/index.vue';

import HeaderComponent from '@/components/common/layouts/header-bar/HeaderComponent.vue';
import Spinner from '@/components/common/layouts/spinner/index.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, reactive, toRefs } from 'vue';
import Util from '@/common/utils/Util';
import FooterComponent from '@/components/common/layouts/footer-bar/FooterComponent.vue';
import { cloneDeep } from 'lodash';

const { getters, dispatch } = useStore();
const route = useRoute();
const keepAlive = reactive([]);
const modalAlertComponents = computed(() => cloneDeep(getters['layout/getModalAlertComponents']));
const modalComponents = computed(() => getters['layout/getModalComponents']);
const isSideBar = computed(() => getters['layout/getIsSideBar']);

/** methods **/
const fnCloseModalAlert = async index => {
  Util.scrollLock(false);
  const lastIndex = index || (modalAlertComponents.value.length > 0 ? modalAlertComponents.value.length - 1 : 0);
  await dispatch('layout/closeModalAlertComponent', { index: lastIndex });
};
const fnCloseModal = async index => {
  Util.scrollLock(false);
  const lastIndex = index || (modalComponents.value.length > 0 ? modalComponents.value.length - 1 : 0);
  await dispatch('layout/closeModalComponent', { index: lastIndex });
};
</script>

<style lang="scss">
.fade-enter-active {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
  transition: opacity 0.3s;
}
</style>
