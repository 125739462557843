import Util from '@/common/utils/Util'
// import { merge } from 'lodash'

export const state = {
  authorization: null,
  data: null
}
export const getters = {
  getAuthorization(state) {
    state.authorization = state.authorization || localStorage.authorization
    return state.authorization
  },
  getData(state) {
    state.data = localStorage.data || '{}'
    return JSON.parse(state.data)
  }
}
export const mutations = {
  setAuthorization(state, { authorization = '' }) {
    state.authorization = authorization
    localStorage.authorization = authorization
  },
  setData(state, { data = {} }) {
    state.data = data
    localStorage.data = JSON.stringify(data)
  },
  storeLogout(state) {
    state.data = null
    state.authorization = null
    delete localStorage.authorization
    delete localStorage.data
    // delete localStorage.isNotShowInfo
  }
}
export const actions = {
  async signIn({ commit }, { authorization, data }) {
    if (!Util.isEmpty(authorization)) {
      commit('setAuthorization', { authorization })
    }
    if (!Util.isEmpty(data)) {
      commit('setData', { data })
    }
  },
  async signOut({ commit }) {
    commit('storeLogout')
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
