import ServiceExec from '@/common/utils/ServiceExec'
import store from '@/store'
// import Util from '@/common/utils/Util'
import { forEach } from 'lodash'
/**
 * User API Service
 * @class SignSvc
 */
class UserSvc extends ServiceExec {
  /**
   * 로그인
   * @param params
   * @returns {*}
   */
  postLogin(params = {}) {
    return this.postResponseText(`/apis/confirmLogin`, params).then(
      response => {
        let authorization = response.headers['authorization']
        if (!response.data.includes('실패')) {
          store.dispatch('user/signIn', {
            authorization: authorization,
            data: { user_id: params.user_id, dong: params.dong, ho: params.ho }
          })
        }
        return response.data
      }
    )
  }

  /**
   * 로그아웃
   * @param params
   * @returns {*}
   */
  getLogout(params = {}) {
    return this.get(`/apis/logout`, params).then(response => {
      store.dispatch('user/signOut')
      return response.data
    })
  }

  /**
   * 회원가입
   * @param params
   * @returns {*}
   */
  postAdd(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    return this.multiPost(`/api/user/add`, formData).then(response => {
      return response.data
    })
  }

  /**
   * 소셜로그인
   * @param params
   * @returns {*}
   */
  postOauthLogin(params = {}) {
    return this.post(`/api/user/oauth/login`, params).then(response => {
      return response.data
    })
  }

  /**
   * 카카오 소셜로그인 인증
   * @param params
   * @returns {*}
   */
  getKakaoOauthLogin(params = {}) {
    return this.get(`/api/user/kakao/oauth`, params).then(response => {
      return response.data
    })
  }

  /**
   * 네이버 소셜로그인 인증
   * @param params
   * @returns {*}
   */
  getNaverOauthLogin(params = {}) {
    return this.get(`/api/user/naver/oauth`, params).then(response => {
      return response.data
    })
  }

  /**
   * 구글 소셜로그인 인증
   * @param params
   * @returns {*}
   */
  getGoogleOauthLogin(params = {}) {
    return this.get(`/api/user/google/oauth`, params).then(response => {
      return response.data
    })
  }

  /**
   * 소셜로그인 회원가입 (추가 정보 입력)
   * @param params
   * @returns {*}
   */
  postSnsSignUp(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    return this.multiPost(`/api/user/snsSignUp`, formData).then(response => {
      return response.data
    })
  }

  /**
   * 이메일 재전송 (위즈니 가입)
   * @param params
   * @returns {*}
   */
  postAddEmailSend(params = {}) {
    return this.post(`/api/user/add/emailSend`, params).then(response => {
      return response.data
    })
  }

  /**
   * 나이스 인증 (휴대폰인증)
   * @param params
   * @returns {*}
   */
  postAuthNiceAuthPop(params = {}) {
    return this.post(`/api/auth/niceAuthPop`, params).then(response => {
      return response.data
    })
  }

  /**
   * 아이디 중복 체크
   * @param params
   * @returns {*}
   */
  postCheckDuplById(params = {}) {
    return this.post(`/api/user/checkDuplById`, params).then(response => {
      return response.data
    })
  }

  /**
   * 중복 닉네임 체크
   * @param params
   * @returns {*}
   */
  postCheckDuplByNick(params = {}) {
    return this.post(`/api/user/checkDuplByNick`, params).then(response => {
      return response.data
    })
  }

  /**
   * 아이디 찾기 – 휴대폰 인증번호 전송
   * @param params
   * @returns {*}
   */
  postFindIdSendAuthValue(params = {}) {
    return this.post(`/api/user/findId/sendAuthValue`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 아이디 찾기 – 휴대폰 인증번호 확인
   * @param params
   * @returns {*}
   */
  postGetIdByPhone(params = {}) {
    return this.post(`/api/user/getIdByPhone`, params).then(response => {
      return response.data
    })
  }

  /**
   * 비밀번호 재설정 – 휴대폰 인증번호 전송
   * @param params
   * @returns {*}
   */
  postSendAuthValueByPhone(params = {}) {
    return this.post(`/api/user/sendAuthValueByPhone`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 비밀번호 재설정 – 휴대폰 인증번호 확인
   * @param params
   * @returns {*}
   */
  postCompareAuthValueByPhone(params = {}) {
    return this.post(`/api/user/compareAuthValueByPhone`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 비밀번호 재설정 – 이메일 인증번호 전송
   * @param params
   * @returns {*}
   */
  postSendAuthValueByEmail(params = {}) {
    return this.post(`/api/user/sendAuthValueByEmail`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 비밀번호 재설정 – 이메일 인증번호 확인
   * @param params
   * @returns {*}
   */
  postCompareAuthValueByEmail(params = {}) {
    return this.post(`/api/user/compareAuthValueByEmail`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 비밀번호 재설정
   * @param params
   * @returns {*}
   */
  postChangePassword(params = {}) {
    return this.post(`/api/user/changePassword`, params).then(response => {
      return response.data
    })
  }

  /**
   * 비밀번호 재설정 – 아이디 확인
   * @param params
   * @returns {*}
   */
  postCheckUserId(params = {}) {
    return this.post(`/api/user/checkUserId`, params).then(response => {
      return response.data
    })
  }

  /**
   * 추천인 유효성 체크
   * @param params
   * @returns {*}
   */
  postRecommender(params = {}) {
    return this.post(`/api/user/recommender`, params).then(response => {
      return response.data
    })
  }
}
export default new UserSvc()
