import Util from '@/common/utils/Util';

const routeState = {
  isHeader: false,
  isBack: false,
  isSideBar: false,
  titleName: ''
};

export const state = {
  isLoading: false,
  isForceLoading: false,
  modalAlertComponents: [],
  modalComponents: [],
  routeLayout: {
    ...routeState
  },
  keepAlive: []
};
export const getters = {
  getIsLoading: state => {
    return state.isLoading;
  },
  getIsForceLoading: state => {
    return state.isForceLoading;
  },
  getIsSideBar: state => {
    return state.isSideBar;
  },
  getModalAlertComponents: state => {
    return state.modalAlertComponents;
  },
  getModalComponents: state => {
    return state.modalComponents;
  },
  getRouteLayout: state => {
    return state.routeLayout;
  },
  getKeepAlive: state => {
    return state.keepAlive;
  }
};
export const mutations = {
  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  setIsForceLoading(state, { isForceLoading }) {
    state.isForceLoading = isForceLoading;
  },
  setIsSideBar(state, { isSideBar }) {
    state.isSideBar = isSideBar;
  },
  setModalAlertComponents(state, { modalAlertComponents }) {
    state.modalAlertComponents = modalAlertComponents;
  },
  setModalComponents(state, { modalComponents }) {
    state.modalComponents = modalComponents;
  },
  setRouteLayout(
    state,
    { isHeader = undefined, isBack = undefined, isSideBar = undefined, isForceLoading = undefined, titleName = '' }
  ) {
    if (isHeader !== undefined) state.routeLayout.isHeader = isHeader;
    if (isBack !== undefined) state.routeLayout.isBack = isBack;
    if (isSideBar !== undefined) state.routeLayout.isSideBar = isSideBar;
    if (isForceLoading !== undefined) state.routeLayout.isForceLoading = isForceLoading;
    if (titleName !== '') state.routeLayout.titleName = titleName;
  },
  setKeepAlive(state, { keepAlive }) {
    state.keepAlive = keepAlive;
  }
};
export const actions = {
  updateIsLoading({ commit }, { isLoading = false }) {
    commit('setIsLoading', { isLoading });
  },
  updateIsForceLoading({ commit }, { isForceLoading = false }) {
    commit('setIsForceLoading', { isForceLoading });
  },
  updateIsSideBar({ commit }, { isSideBar = false }) {
    commit('setIsSideBar', { isSideBar });
  },
  pushModalAlertComponent({ state, commit }, payload) {
    Util.scrollLock(true);
    state.modalAlertComponents.push(payload);
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    });
  },
  removeAllModalAlertComponent({ commit }) {
    commit('setModalAlertComponents', {
      modalAlertComponents: []
    });
  },
  closeModalAlertComponent({ state, commit }, { index = 0 }) {
    state.modalAlertComponents.splice(index, 1);
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    });
  },
  pushModalComponent({ state, commit }, payload) {
    Util.scrollLock(true);
    state.modalComponents.push(payload);
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    });
  },
  removeAllModalComponent({ commit }) {
    commit('setModalComponents', {
      modalComponents: []
    });
  },
  closeModalComponent({ state, commit }, { index = 0 }) {
    state.modalComponents.splice(index, 1);
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    });
  },
  updateRouteLayout({ commit }, payload) {
    commit('setRouteLayout', payload);
  },
  updateKeepAlive({ commit }, payload) {
    commit('setKeepAlive', payload);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
