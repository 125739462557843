import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Util from '@/common/utils/Util';

const updateRouteLayout = async meta => {
  await store.dispatch('layout/updateRouteLayout', {
    isHeader: meta.isHeader,
    isBack: meta.isBack,
    titleName: meta.titleName
  });
};

const routes = [
  {
    path: '',
    redirect: '/main'
  },
  {
    path: '/main',
    component: () => import('@/views/main/Main.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: '메인'
    }
  },
  {
    path: '/portfolio',
    component: () => import('@/views/portfolio/Portfolio.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: '포트폴리오'
    }
  },
  {
    path: '/directions',
    name: 'directions-index',
    component: () => import('@/views/directions/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: '오시는길'
    }
  },
  {
    path: '/estimate',
    // component: () => import('@/views/estimate/index.vue'),
    component: () => import('@/views/estimate/Estimate.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: '견적문의'
    }
  },
  {
    path: '/production/info',
    name: 'production-info',
    component: () => import('@/views/production/Product.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: '제작과정'
    }
  },
  {
    path: '/production/process',
    name: 'production-process',
    component: () => import('@/views/production/process.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: '어플제작과정'
    }
  },
  {
    path: '/faq',
    // component: () => import('@/views/faq/index.vue'),
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      titleName: 'FAQ'
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     if (savedPosition) {
    //       resolve(savedPosition)
    //     } else {
    //       resolve({ left: 0, top: 0 })
    //     }
    //   }, 500)
    // })
  }
});

window.routeQuery = '';
// const signInPages = ['/main'];
const fnRemoveAllModalAlertComponent = () => {
  store.dispatch('layout/removeAllModalAlertComponent');
};
const fnRemoveAllModalComponent = () => {
  store.dispatch('layout/removeAllModalComponent');
};

router.beforeEach(async (to, from, next) => {
  let meta = to.meta;
  // const userData = store.getters['user/getData'];
  // if (Util.isEmpty(userData)) {
  //   if (signInPages.indexOf(to.path) > -1) {
  //     next({ path: '/main' });
  //     return;
  //   }
  // }
  if (!Util.isEmpty(store.getters['layout/getModalAlertComponents'])) {
    fnRemoveAllModalAlertComponent();
  }
  if (!Util.isEmpty(store.getters['layout/getModalComponents'])) {
    fnRemoveAllModalComponent();
  }

  // /** 사이드 메뉴 페이지 이동시마다 종료  */
  if (document.getElementById('btn-hamburger-wrap').checked === true) {
    document.getElementById('btn-hamburger-wrap').checked = false;
  }

  await updateRouteLayout(meta);
  window.routeQuery = to.query;
  next();
});

export default router;
