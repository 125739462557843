import ServiceExec from '@/common/utils/ServiceExec';

class PortfolioSvc extends ServiceExec {
  /**
   * 카테고리 리스트
   * @returns {*}
   */
  getCategoryList() {
    return this.get(`/api/portfolio/category`).then(response => {
      return response.data;
    });
  }

  /**
   * 포트폴리오 리스트
   * @param params
   * @returns {*}
   */
  getPortfolioList(params = {}) {
    return this.get(`/api/portfolio`, params).then(response => {
      return response.data;
    });
  }

  /**
   * 포트폴리오 상세
   * @param portfolioNo
   * @returns {*}
   */
  getPortfolio(portfolioNo) {
    return this.get(`/api/portfolio/${portfolioNo}`).then(response => {
      return response.data;
    });
  }
}
export default new PortfolioSvc();
