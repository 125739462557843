<template>
  <div class="pop_center">
    <!--'팝업 나타날 때: on 추가'-->
    <div class="pop_table">
      <div class="pop_table_cell">
        <div class="pop_con">
          <div class="pop_header">
            <h1>{{ layout.title }}</h1>
          </div>
          <div class="pop_main">
            <div class="pop_main_inner">
              <p v-html="layout.contents"></p>
            </div>
          </div>
          <div class="pop_btm">
            <button @click="fnCallBack">{{ callBackText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue';

export default {
  name: 'modal-alert-base',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callBackText: {
      type: [String],
      default: '확인'
    },
    callBack: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance();
    const fnCallBack = event => {
      props.callBack(proxy, event);
    };
    return { fnCallBack };
  }
};
</script>

<style scoped></style>
