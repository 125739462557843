<template>
  <div class="modal-dim" :class="{ on: on }">
    <!--'on 추가'-->
    <div class="modal-wrap">
      <button class="btn-modal-close" @click="close"></button>
      <!-- Swiper -->
      <div class="portfolio-detail-wrap">
        <Swiper
          :modules="modules"
          :virtual="true"
          :centeredSlides="true"
          :spaceBetween="1300"
          :initialSlide="page"
          :navigation="{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
          }"
        >
          <!--
                    class="modal-inner center swiper mySwiper modal-swiper"
                  :initial-slide="page"
              @slideChange="onSlideChange"
                    :effect="'coverflow'"
                    :centered-slides="true"
                    :slides-per-view="'auto'"
                    :coverflow-effect="coverFlowOptions"
          -->
          <swiper-slide
            class="swiper-slide"
            v-for="(pofol, index) in portfolioList"
            :key="pofol.portfolioNo"
            :virtual-index="pofol.portfolioNo"
          >
            <div class="modal">
              <div class="portfolio-detail" :key="`inner_${pofol.portfolioNo}`">
                <!--               v-if="visible(index)" -->
                <!--좌측-->
                <div class="portfolio-detail-inner">
                  <div class="portfolio-detail-area">
                    <div class="title-wrap">
                      <p>{{ pofol.categoryName }}</p>
                      <h2 class="title">{{ pofol.projectName }}</h2>
                    </div>
                    <ul class="portfolio-info-wrap">
                      <li>
                        <p class="portfolio-info-ttl">Platform</p>
                        <div class="inner">
                          <p>{{ pofol.platform }}</p>
                        </div>
                      </li>
                      <li>
                        <p class="portfolio-info-ttl">Technique</p>
                        <div class="inner dev-flex-gap-6">
                          <div class="tag purge-margin-bottom" v-for="tag in tagSplit(pofol)" :key="tag">
                            {{ tag }}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="portfolio-info-txt">
                      <h6 class="title">{{ pofol.projectSimpleDescription }}</h6>
                      <pre class="dev-word-wrap" v-html="pofol.projectDescription"></pre>
                    </div>
                  </div>
                  <div class="contact-btn-box">
                    <div class="btn-wrap">
                      <button class="btn gray" @click="telTo">
                        <img src="@/assets/static/images/ico-call-gr90.svg" alt="전화문의" />전화문의
                      </button>
                      <button class="btn kakao" @click="goKakaoChannel">
                        <img src="@/assets/static/images/ico-kakao.svg" alt="카카오 채널" />카카오 채널
                      </button>
                    </div>
                    <button class="quote-contact-btn" @click="goEstimate">
                      견적 문의
                      <i class="ico-arrow-right-white"></i>
                    </button>
                  </div>
                </div>
                <!--우측-->
                <div class="portfolio-detail-inner">
                  <div class="mockup-slide" :class="{ 'mockup-pc': isMobile }">
                    <div class="control-prev  dev-cursor-pointer" :class="`control-prev-${index}`"></div>
                    <swiper
                      class="mockup-slide-ul"
                      :allow-touch-move="false"
                      :modules="innerModules"
                      :allow-slide-next="true"
                      :allow-slide-prev="true"
                      :hash-navigation="true"
                      :loop="true"
                      :navigation="{
                        nextEl: `.control-next-${index}`,
                        prevEl: `.control-prev-${index}`
                      }"
                    >
                      <swiper-slide class="mockup-slide-li" v-for="img in pofol.imageList" :key="img.storedFileName">
                        <img :src="ConstCode.getImagePath(img.storedFileName, 'origin')" alt="image" />
                      </swiper-slide>
                    </swiper>
                    <div class="control-next dev-cursor-pointer" :class="`control-next-${index}`"></div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </Swiper>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, defineEmits, defineProps, onMounted, ref, toRefs } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/virtual';
import { useRouter } from 'vue-router';
import ConstCode from '@/common/constants/ConstCode';
import { useStore } from 'vuex';
import { telTo, goKakaoChannel } from '@/common/utils/suppliment';

const { getters } = useStore();
const { push } = useRouter();
const emits = defineEmits(['close']);
const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  list: {
    type: Array,
    required: true
  }
});

onMounted(() => {
  globalThis.addEventListener('keydown', event => {
    if (event.key === 'Escape') {
      close();
    }
  });
  const timeout = setTimeout(() => {
    on.value = true;
    clearTimeout(timeout);
    const scrollTarget = document.getElementsByTagName('html')[0];
    if (!scrollTarget) return;
    scrollTarget.style.overflowY = 'hidden';
  }, 50);
});

onBeforeMount(() => {
  page.value = props.index;
});

onBeforeUnmount(() => {
  const scrollTarget = document.getElementsByTagName('html')[0];
  if (!scrollTarget) return;
  scrollTarget.style.overflowY = 'auto';
  const timeout = setTimeout(() => {
    on.value = false;
    clearTimeout(timeout);
  }, 50);
});

// Swiper 설정값
const modules = [Navigation, Virtual]; //, EffectCoverflow];
const innerModules = [Navigation];

const isMobile = computed(() => {
  return !ConstCode.isMobile();
});
const on = ref(false);
const portfolio = ref({});
const page = ref();
const { list } = toRefs(props);
const portfolioList = computed(() => [...list.value]);
const tagSplit = ({ tag }) => tag.split(',').map(elem => elem.trim());

const goEstimate = () => {
  close();
  push('/estimate');
};

const close = () => {
  on.value = false;
  const timeout = setTimeout(() => {
    emits('close');
    clearTimeout(timeout);
  }, 500);
};
</script>
<style scoped lang="scss">
.modal-dim {
  z-index: 100;
}
</style>
<!--
homepage-vue
Created at 2024-03-18
-->
