/** 모달 ALERT **/
import BASE from '@/components/common/modal/alert/base/index.vue';
import CONFIRM from '@/components/common/modal/alert/confirm/index.vue';
import PORTFOLIO_DETAIL from '@/components/views/portfolio/detail/index';
import PRIVACY from '@/components/views/terms/privacy/index';
import PORTFOLIO_DETAIL_NEW from '@/components/views/portfolio/detail/PortfolioDetail.vue';
import MAP_AREA_NEW from '@/components/views/map/MapDetail.vue';
/** 전체화면 **/

export const modalAlertNames = {
  BASE,
  CONFIRM,
  PORTFOLIO_DETAIL,
  PORTFOLIO_DETAIL_NEW,
  MAP_AREA_NEW,
  PRIVACY
};

export const modalNames = {};
