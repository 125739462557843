<template>
  <div class="pop_cover">
    <div class="pop_base">
      <div class="inner">
        <div class="pop_cont clearfix">
          <div class="cont_inner">
            <button class="btn_pop_cls" @click="$emit('close')"></button>
            <div class="pop_sec right full">
              <div class="title_area">
                <h1>개인정보보호정책</h1>
              </div>
              <div class="txt_area">
                <ul>
                  <li>
                    <h3><span class="i_chk_gr"></span>개인정보 수집 이용에 대한 동의</h3>
                    <p>
                      회원님의 소중한 개인정보는 다음과 같은 정책에 따라 수집 및 이용됩니다.<br />
                      앱지는 해당 목적에 연관되는 개인정보만을 수집하며, 수집된 정보를 투명하고 안전하게 보호 관리할
                      것을 약속합니다.<br />
                      이에 개인정보 수집 및 이용에 대한 동의를 구합니다.
                    </p>
                  </li>
                  <li>
                    <h3><span class="i_chk_gr"></span>개인정보의 수집이용 목적</h3>
                    <p>
                      회원님의 프로젝트에 대한 견적, 기간, 개발방법 등의 문의에 대한 정보가 보다 정확한 답변을 위해
                      수집됩니다.<br />
                      - 수집항목 : 회사명, 직위, 성명, 이메일, 연럭처, 홈페이지, 서비스 종류, 제작 구분, 예산, 첨부
                      파일, 문의 내용
                    </p>
                  </li>
                  <li>
                    <h3><span class="i_chk_gr"></span>보유이용 기간</h3>
                    <p>
                      원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.<br />
                      단, 아래의 사유로 명시한 기간 동안 보존합니다.
                    </p>
                  </li>
                  <li>
                    <h3><span class="i_chk_gr"></span>보존 이유</h3>
                    <p>
                      회원님의 동의를 통한 정보 유지
                    </p>
                  </li>
                  <li>
                    <h3><span class="i_chk_gr"></span>보존 기간</h3>
                    <p>
                      회원정보 삭제 요청시까지
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-terms-privacy',
  emits: ['close']
};
</script>

<style scoped></style>
