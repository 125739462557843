import store from '@/store';

class Util {
  constructor() {
    this.store = store;
  }
  isEmpty = value => {
    return (
      value === '' ||
      value === null ||
      value === undefined ||
      (value != null && typeof value === 'object' && !Object.keys(value).length)
    );
  };

  formatNumber(value) {
    if (this.isEmpty(value)) return 0;
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  isMobile = () => {
    return navigator.userAgent.toLowerCase().indexOf('mobi') > -1;
  };

  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
      console.log('오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다');
      return str;
    }
    str += ''; // 문자로
    padStr += ''; // 문자로
    while (str.length < padLen) str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  toggle;

  isNumeric(value) {
    return !isNaN(Number(value));
  }

  byteToHex(byteNum) {
    let digits = byteNum.toString(16);
    if (byteNum < 16) return '0' + digits;

    return digits;
  }

  bytesToHexString(bytes) {
    let result = '';
    for (let i = 0; i < bytes.length; i++) {
      result += this.byteToHex(bytes[i]);
    }
    return result;
  }

  stringToUtf8Bytes(text) {
    let result = [];
    if (text != null) {
      for (let i = 0; i < text.length; i++) {
        const c = text.charCodeAt(i);
        if (c <= 0x7f) {
          result.push(c);
        } else if (c <= 0x07ff) {
          result.push(((c >> 6) & 0x1f) | 0xc0);
          result.push((c & 0x3f) | 0x80);
        } else {
          result.push(((c >> 12) & 0x0f) | 0xe0);
          result.push(((c >> 6) & 0x3f) | 0x80);
          result.push((c & 0x3f) | 0x80);
        }
      }
    }
    return result;
  }

  /**
   * 이메일 정규식
   *  - test123@test.com
   * @class Util
   */
  isEmailValid(value) {
    if (this.isEmpty(value)) return false;
    let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])+.[a-zA-Z]{2,3}$/i;
    return regExp.test(value);
  }

  scrollLock(isLock = false) {
    document.body.className = '';
    if (isLock) document.body.className = 'scroll_lock';
    if (isLock) document.body.style = 'overflow: hidden;';
    if (!isLock) document.body.style = '';
  }
}
export default new Util();
