import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import '@/assets/static_v1/css/common.css';
// import '@/assets/static_v1/css/style.css';
// import '@/assets/static_v1/css/main.css';

import '@/assets/static/css/main.scss';

import Util from '@/common/utils/Util';
import ConstCode from '@/common/constants/ConstCode';
import '@/common/GlobalFunction';
import { modalAlertNames, modalNames } from './components/common/globalComponent';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { DatePicker } from 'v-calendar';

import UserSvc from '@/common/service/UserSvc';
import CommonSvc from '@/common/service/CommonSvc';
import EstimateSvc from '@/common/service/EstimateSvc';
import BoardSvc from '@/common/service/BoardSvc';

import VueScrollTo from 'vue-scrollto';

import { ObserveVisibility } from 'vue-observe-visibility';

import ComponentObserveVisibility from '@/components/common/observe-visibility';
import QrReader from 'vue3-qr-reader';
import VueGtag from 'vue-gtag';

const app = createApp(App);

// app.config.globalProperties.$Util = Util;
// app.config.globalProperties.$ConstCode = ConstCode;
// app.config.globalProperties.$dayjs = dayjs;
// app.config.globalProperties.$_ = lodash;

// app.config.globalProperties.$UserSvc = UserSvc;
// app.config.globalProperties.$CommonSvc = CommonSvc;
// app.config.globalProperties.$EstimateSvc = EstimateSvc;
// app.config.globalProperties.$BoardSvc = BoardSvc;

// app.config.globalProperties.$modalAlertNames = modalAlertNames;
// app.config.globalProperties.$modalNames = modalNames;

// 전역 컴포넌트 등록
for (let key in modalAlertNames) {
  app.component(modalAlertNames[key].name, modalAlertNames[key]);
}
for (let key in modalNames) {
  app.component(modalNames[key].name, modalNames[key]);
}

app.component('DatePicker', DatePicker);
app.component('observe-visible', ComponentObserveVisibility);
app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind
});

app.use(QrReader);
app.use(VueGtag, {
  config: { id: 'AW-663122449' }
})

app
  // .use(VueScrollTo, {
  //   container: 'body',
  //   duration: 500,
  //   easing: 'ease',
  //   offset: 0,
  //   force: true,
  //   cancelable: true,
  //   onStart: false,
  //   onDone: false,
  //   onCancel: false,
  //   x: false,
  //   y: true
  // })
  .use(store)
  .use(router)
  .mount('body');
