import { createStore } from 'vuex';
import layout from '@/store/modules/layout/index';
import user from '@/store/modules/user/index';
import portfolio from '@/store/modules/portfolio/index';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout,
    user,
    portfolio
  }
});
