<template>
  <div class="modal-dim " :class="{ on: on }">
    <!--'on 추가'-->
    <div class="modal-wrap">
      <button class="btn-modal-close" @click="close"></button>
      <div class="modal-inner">
        <div class="modal">
          <h1 class="section-ttl">오시는 길<span class="line"></span></h1>

          <map-area />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MapArea from '@/components/views/common/MapArea.vue';
import { onBeforeUnmount, onMounted, ref, defineEmits } from 'vue';

const emits = defineEmits(['close']);
onMounted(() => {
  const timeout = setTimeout(() => {
    on.value = true;
    clearTimeout(timeout);
    const scrollTarget = document.getElementsByTagName('html')[0];
    if (!scrollTarget) return;
    scrollTarget.style.overflowY = 'hidden';

    if (document.getElementById('btn-hamburger-wrap').checked === true) {
      document.getElementById('btn-hamburger-wrap').checked = false;
    }
  }, 50);
});
onBeforeUnmount(() => {
  const scrollTarget = document.getElementsByTagName('html')[0];
  if (!scrollTarget) return;
  scrollTarget.style.overflowY = 'auto';
  const timeout = setTimeout(() => {
    on.value = false;
    clearTimeout(timeout);
  }, 50);
});
const on = ref(false);

const close = () => {
  on.value = false;
  const timeout = setTimeout(() => {
    emits('close');
    clearTimeout(timeout);
  }, 500);
};
</script>

<style scoped lang="scss">
.modal-dim {
  z-index: 100;
}
</style>
<!--
homepage-vue
Created at 2024-03-19
-->
