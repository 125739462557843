import ServiceExec from '@/common/utils/ServiceExec';
import { forEach } from 'lodash';
import queryString from 'query-string';
/**
 * Common API Service
 * @class SignSvc
 */
class CommonSvc extends ServiceExec {
  uploadFile(params = {}) {
    const formData = new FormData();
    forEach(params, function(value, key) {
      formData.append(key, value);
    });
    return this.post(`/api/common/upload`, formData).then(response => {
      return response.data;
    });
  }

  downloadFile(params = {}) {
    return this.ServiceExec.request({
      method: 'GET',
      url: `/api/common/download?${queryString.stringify(params)}`,
      responseType: 'blob'
    });
  }
}
export default new CommonSvc();
