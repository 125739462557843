import ServiceExec from '@/common/utils/ServiceExec';

class BoardSvc extends ServiceExec {
  /**
   *  게시판 리스트
   *  @param boardType {integer} 보드타입 (1: FAQ)
   *  @param category {string} 카테고리명
   *  @param endDate {string} 종료일
   *  @param limit {integer} 페이지당 게시물 수
   *  @param no {integer} 고유번호 검색
   *  @param offset {integer} 시작 row
   *  @param page {integer} 페이지번호
   *  @param searchText {string} 검색어
   *  @param searchType {string} 검색타입
   *  @param startDate {string} 시작일
   */
  getBoardList(params = {}) {
    return this.get(`/api/board`, params).then(response => {
      return response.data;
    });
  }

  /**
   *  게시판 상세
   *  @param boardNo {integer} boardNo
   */
  getBoard(params = {}) {
    return this.get(`/api/board/${params}`, params).then(response => {
      return response.data;
    });
  }
}
export default new BoardSvc();
